const productPage = document.querySelector(".single-products");

// Get next departure from iTicket
if (productPage) {
	const productCode = productPage
		.querySelector(".product-details")
		.getAttribute("data-iticket-productcode");
	const depareureTextField = productPage.querySelector(".next-departure");
	const loader = productPage.querySelector(".loader");
	const todayDateString = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

	jQuery.ajax({
		type: "POST",
		url: rodne_scripts.ajax_url,
		data: {
			action: "get_product_departures",
			product_code: productCode,
		},
		success: function (output) {
			let prices = output["prices"];
			if (prices.length > 0) {
				let respons = output["prices"][0]["dates"];
				let nextDeparture = null;
				let nextOccasions = null;
				let nextDay = false;

				console.log(respons);

				// Loop through all departures
				respons.some((date, index) => {
					// Get datetime from for individual departure
					let datetime = new Date(date["date"])
						.toISOString()
						.split("T")[0]; // Get datetime's date in YYYY-MM-DD format

					// Check if departure is sold out and if it's in the future
					if (
						datetime >= todayDateString &&
						date["soldOut"] == false
					) {
						const dateTimeDateString = new Date(datetime)
							.toISOString()
							.split("T")[0]; // Get datetime's date in YYYY-MM-DD format

						// Check if departure is on not the same day as today
						if (dateTimeDateString !== todayDateString) {
							nextDay = true;
						}

						nextDeparture = date;
					} else {
						nextDay = true;
						return false;
					}

					// If departure is found, try to find next occasion
					if (nextDeparture) {
						let occasions = nextDeparture["occasions"];

						// Loop through all occasions
						occasions.some((occasion, index) => {
							let occasionTime = occasion["startTime"];

							const timeNow = new Date();
							const options = {
								hour: "2-digit",
								minute: "2-digit",
								hour12: false,
							};
							const formattedTime = timeNow.toLocaleTimeString(
								"en-GB",
								options
							);

							// Check if occasion is in the future get first available occasion, else get next available occasion today
							if (nextDay) {
								nextOccasions = occasion;
								return true;
							} else if (
								occasionTime > formattedTime &&
								occasion["availability"] > 0
							) {
								nextOccasions = occasion;
								return true;
							} else {
								return false;
							}
						});

						// If next occasion is found, return true to break the loop
						if (nextOccasions) {
							return true;
						}
					}
				});

				const lang = document.documentElement.lang;
				let nextDepartureDate;
				let nextDepartureTime;

				// If next departure and next occasion is found, format the dates, else show "no future departures"-message
				if (nextDeparture && nextOccasions) {
					if (lang == "nb-NO") {
						// Format the dates
						nextDepartureDate = new Date(
							nextDeparture["date"]
						).toLocaleDateString("no-NB", {
							day: "numeric",
							month: "long",
						});
						nextDepartureTime = nextOccasions["startTime"];
					} else {
						nextDepartureDate = new Date(
							nextDeparture["date"]
						).toLocaleDateString("en-US", {
							day: "numeric",
							month: "long",
						});
						nextDepartureTime = nextOccasions["startTime"];
					}

					// print out next departure
					if (nextDeparture) {
						depareureTextField.innerHTML =
							nextDepartureTime + ", " + nextDepartureDate;
					}
				} else {
					const noFutureDepartures = productPage.querySelector(
						".no-future-departures"
					);
					const depareureTextField = productPage.querySelector(
						".next-departure-pre-text"
					);
					noFutureDepartures.classList.remove("hidden");
					depareureTextField.classList.add("hidden");
				}
			}

			// Hide loader
			loader.classList.add("hidden");
		},
	});
}

// Connect product details links to accordion
if (productPage) {
	const productDetails = document.querySelector(".product-details");
	const allLinks = productDetails.querySelectorAll("a");
	const accordions = document.querySelectorAll(".accordion .element-wrapper");

	// put accordions in object where data-id is key
	const accordionObj = {};
	accordions.forEach((accordion) => {
		const id = accordion.getAttribute("data-id");
		accordionObj[id] = accordion;
	});

	//get the url and split out #

	allLinks.forEach((link) => {
		link.addEventListener("click", function (e) {
			// Get href attribute without #
			const href = link.getAttribute("href").split("#")[1];

			// Check if href is in accordionObj
			if (accordionObj[href]) {
				e.preventDefault();
				// Open accoridon with class
				accordionObj[href].classList.add("show-dropdown");

				// Scroll down to accordion
				const offset = accordionObj[href].offsetTop - 100;
				window.scrollTo({
					top: offset,
					behavior: "smooth",
				});
			}
		});
	});
}
